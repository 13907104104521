<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Importer une image produit"
        class="background-color"
      >

        <b-row>
          <b-col md="4">
            <h5>Image :</h5>
            <b-form-file
              v-model="file"
              placeholder="Choisir un fichier ou glisser..."
              drop-placeholder="Drop fichier ici..."
              accept=".jpg, .png"
            />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <h5>Produit :</h5>
              <v-select
                v-model="selectedProduct"
                placeholder="Choisissez le produit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="products"
                :get-option-label="option => `${option.country} - ${option.title}`"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center mt-2"
            md="8"
          >
            <b-form-group>
              <b-button
                variant="primary"
                @click="upload"
              >
                Enregistrer l'image
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <span
          v-if="selectedProduct && selectedProduct.images && selectedProduct.images.length"
          class="mt-2"
        ><strong>Images déjà enregistrées:</strong></span>
        <b-row
          v-if="selectedProduct && selectedProduct.images && selectedProduct.images.length"
          class="mt-2"
        >
          <b-col
            v-for="(image, key) of selectedProduct.images"
            :key="key"
            md="4"
          >
            <div>
              <a :href="image"><b-img
                :src="image"
                rounded
                width="250"
                height="200"
              /></a>
            </div>
          </b-col>
        </b-row>
      </b-card>
    <!--/ Gradient color start -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormFile, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BFormFile,
    vSelect,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      file: null,
      rangeDate: null,
      selected: '',
      option: ['desktop', 'mobile'],
      products: [],
      selectedProduct: '',
      images: [],
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      try {
        const { products } = await this.$http.get('/admin/products')
        this.products = products
        // eslint-disable-next-line prefer-destructuring
        this.selectedProduct = this.products[0]
      } catch (err) {
        console.log(err)
      }
    },
    async upload(f) {
      console.log(this.selectedProduct)
      if (!this.file || !this.selectedProduct || this.selectedProduct === '') {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez renseigner tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          await this.$http.post(`/admin/products/${this.selectedProduct._id}/upload`, form)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre image a bien été sauvegardée.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.getProducts()
        } catch (err) {
          console.log(err)
        }
      }
    },
    async dispatch() {
      try {
        if (this.selected === '') {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner un logisticien.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (!this.rangeDate) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner une date de dispatch.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        const data = await this.$http({
          url: '/dispatch',
          method: 'POST',
          responseType: 'blob',
          data: {
            selected: this.selected,
            rangeDate: this.rangeDate,
          },
        })
        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `dispatch-${this.selected}-${this.rangeDate}.csv`)
        document.body.appendChild(fileLink)

        fileLink.click()
        return data
      } catch (err) {
        return err
      }
    },
  },
}
</script>

<style lang="scss">
.colors-list {
  li {
    padding: 0.4rem;
    svg {
      margin-right: 0.25rem;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
